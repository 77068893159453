
import { NavLink } from 'react-router-dom';
// material-ui
import { Box, Grid, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { FormattedMessage, useIntl } from "react-intl";
import { contentWidth } from 'store/constant';
import logo from 'assets/images/logo.svg';
import LocalizationSection from '../Header/LocalizationSection/index';
import config from '../../../config';
import useAuth from 'hooks/useAuth';
import useMaintenance from 'hooks/useMaintenance';

// ==============================|| MAIN  HEADER ||============================== //

const Footer = () => {
    const locales_sb = useSelector((state) => state?.shop?.maintenance?.locales);
    const intl = useIntl();
    const settings = useMaintenance();
    const current_lang = useSelector((state) => state.customization.locale);
    const { isLoggedIn } = useAuth();
    const theme = useTheme();
    const supportLinks = [
        // { title: <FormattedMessage id="footer.column-one-link-one"/>, url: isLoggedIn ? '/contact' : '', extUrl: isLoggedIn ? '' : config.links.dp_sales.replace('${locale}', current_lang) }, 
        { title: <FormattedMessage id="footer.column-one-link-one"/>,  url: '/contact'}, 
        { title: <FormattedMessage id="footer.column-one-link-two"/> , url: '/faq' },
        { title: <FormattedMessage id="link.terms-and-conditions-text"/>,   extUrl: settings?.terms_of_use?.url },
        { title: <FormattedMessage id="link.privacy-text"/>,   extUrl: settings?.privacy_notice?.url },
    ];
    const aboutLinks = [
        { title: <FormattedMessage id="footer.dp-marketing-text"/>,   extUrl: config?.links?.dp_marketing?.replace('${locale}', current_lang) },
        { title: <FormattedMessage id="footer.about-philips-text"/>,  extUrl: config?.links?.about?.replace('${locale}', current_lang) }
    ]


    //  if(config?.links?.terms_and_conditions){
    //         supportLinks.push({ 
    //             title: <FormattedMessage id="footer.column-one-link-tc"/>, 
    //             extUrl: config.links.terms_and_conditions.replace('${locale}', current_lang)
    //         })
    //     };
    //  }

    // if(config?.links?.terms_of_use){
    //     supportLinks.push({ 
    //         title: <FormattedMessage id="footer.column-one-link-tu"/>, 
    //         extUrl: config.links.terms_of_use.replace('${locale}', current_lang)
    //     })
    // };

    return (
        <Box sx={{backgroundColor: theme.palette.primary.main, width: '100%', paddingTop: '93px', paddingBottom: '93px', overflow: 'hidden' }}>
            <Grid container direction={{ xs: 'column', sm:'row' }} sx={{ maxWidth: contentWidth, marginRight: 'auto', marginLeft: 'auto' }} spacing={2}>
                <Grid item xs={locales_sb?.length > 1 ? 3 : 4}>
                    <img src={logo} height="76" alt="Philips Logo white" />
                </Grid>
                <Grid item xs={locales_sb?.length > 1 ? 3 : 4}>
                    <Typography variant="bold" color="primary.light">
                        <FormattedMessage id="footer.column-one-header"/>
                    </Typography>
                    <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        { supportLinks.map( (link, i) => <>
                            {link.url ? <NavLink           key={i} style={{color: theme.palette.primary.light, textDecoration: 'none', marginBottom: 10 }} to={link.url}>{ link.title }</NavLink>
                                      : <a target="_blank" key={i} style={{color: theme.palette.primary.light, textDecoration: 'none', marginBottom: 10 }} href={link.extUrl} rel="noreferrer">{ link.title }</a> }
                        </>)}
                    </Box>
                </Grid>
                <Grid item xs={locales_sb?.length > 1 ? 3 : 4}>
                    <Typography variant="bold" color="primary.light">
                        <FormattedMessage id="footer.column-two-header"/>
                    </Typography>
                    <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        { aboutLinks.map( (link, i) => <>
                            {link.url ? <NavLink           key={i} style={{color: theme.palette.primary.light, textDecoration: 'none', marginBottom: 10 }} to={link.url}>{ link.title }</NavLink>
                                      : <a target="_blank" key={i} style={{color: theme.palette.primary.light, textDecoration: 'none', marginBottom: 10 }} href={link.extUrl} rel="noreferrer">{ link.title }</a> }
                        </>)}
                    </Box>
                    <span style={{ color: 'rgb(0, 43, 92)'}}>v new api</span>
                </Grid>
                {locales_sb?.length > 1 && <Grid item xs={3}>
                    <Typography variant="bold" color="primary.light">
                        <FormattedMessage id="footer.column-three-header"/>
                    </Typography>
                    <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <LocalizationSection nav={false} />
                    </Box>
                </Grid>}

            </Grid>
            <Grid container direction={{ xs: 'column', sm:'row' }} sx={{ maxWidth: contentWidth, marginTop: '100px', marginRight: 'auto', marginLeft: 'auto' }} spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="extraSmall" color="primary.light">
                        © Koninklijke Philips N.V. 2004 - {new Date().getFullYear()}. <FormattedMessage id="footer.bottom-right-copyright"/>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};


export default Footer;
