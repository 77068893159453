import PropTypes from 'prop-types';
import { createContext, useEffect, useState, useCallback } from 'react';

import { LOGIN, LOGIN_AS, LOGOUT, SET_LOGGED_IN_REDIRECT, RESET_PASSWORD, SNACKBAR_OPEN, RESET_CART } from 'store/actions';

import config from '../config';
import { SignJWT } from 'jose';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import * as actionTypes from 'store/actions';

const AUTH_API = config.api_endpoint.replace('/admin/','/auth/')

const setSession = (token) => {
    if (token) {
        localStorage.setItem('token', token);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common.Authorization;
    }
};

// GA360 stuff // TODO: should be moved to ist own module ?

const trigger_event = (eddlAction) => {
    console.debug(eddlAction);
    if (!eddlAction) return;
    if (!window['trigger_event']) return;
    try {
        window['trigger_event'](eddlAction);
    } catch (e) {
        console.warn(e);
    };
};

const virtual_pageview = (path) => {
    const path_els = path.split('/');
    let page_name = 'HOME';
    if (path_els.length > 1) {
        switch (path_els[1]) {
            case 'c-p':
                if (path_els.length === 4) {
                    page_name = `CAT: ${path_els[2]}`;

                } else {
                    page_name = `PRD: ${path_els[2]}`;
                }
                break;
            default:
                if (path_els[1].length > 0) {
                    page_name = path_els.slice(1).join('_');
                }
                break;
        };
    }
    const eddlAction = {
        trigger: 'event.proshop.navigation',
        event: 'pageEvent',
        interaction: {
            goal: 'virtual_pageview',
            description: page_name,
            url: path,
            interactive: true, // ??
        },
    };
    return trigger_event(eddlAction);
}

const format_product = (product, extra) => { // for use in eddl events etc
    const prod_data = {
        ...extra,
        id: !!product.ctn ? product.ctn : product.sku,
        locale: product.language ? `${product.language}_${product.language.toUpperCase()}` : 'en_US',
        price: product.price,
        // originalPrice: product.orig_price,
        catalogType: 'PROFESSIONAL',
        // grpCode: `${this.get_prx(product, 'grp')}`,
        // catCode: `${this.get_prx(product, 'cat')}`,
        // subcatCode: 'HEALTHYWHITEPLUS_ORAL_CARE', // need to have this in out data ot het from PRX
        // stock: 0,
        // displayTypes: ['STOCK_RETAILER_ONLINE','NO_STOCK_NOTIFY_ME'],
        // marketPlaceParticipant: 'Philips'
    }
    return prod_data;
}

const product_detail_event = (product) => {
    if (!product) return;
    // const prx_url = `https://www.philips.nl/prx/product/B2C/nl_NL/CONSUMER/products/${product.id}.summary`;

    const eddlAction = {
        trigger: 'ecommerce.product.detail',
        event: 'ecommerceEvent',
        marketPlaceParticipant: 'Philips',
        ecommerce: {
            currencyCode: 'EUR', // TODO get from config
            detail: {
                products: [format_product(product, {})]
            }
        }
    }
    return trigger_event(eddlAction);
}

const product_addremove_event = (product, count, cart) => {
    if (!product || !cart) return;

    const eddlAction = {
        trigger: count > 0 ? 'ecommerce.cart.add' : 'ecommerce.cart.remove',
        event: 'ecommerceEvent',
        ecommerce: {
            currencyCode: 'EUR', // TODO get from config
            cart: {
                // id: '1234-5678-0987', // TODO: create uniq cart ID ?
                products: cart.map(pr => format_product(pr, { quantity: pr.quantity }))
            }
        }
    };
    if (count > 0) {
        eddlAction.ecommerce.add = {
            products: [format_product(product, { quantity: count })]
        };
    }
    if (count < 0) {
        eddlAction.ecommerce.remove = {
            products: [format_product(product, { quantity: -count })]
        };
    }
    return trigger_event(eddlAction);
}

const show_cart_event = (cart) => {
    if (!cart) return;
    const eddlAction = {
        trigger: 'ecommerce.checkout.cartview',
        event: 'ecommerceEvent',
        // marketPlaceParticipant: 'complete-order', // ??
        marketPlaceParticipant: 'Philips',
        ecommerce: {
            currencyCode: 'EUR',
            cart: {
                products: cart.map(pr => format_product(pr, { quantity: pr.quantity }))
            }
        }
    };
    return trigger_event(eddlAction);
}

const checkout_step_event = (step, cart) => {
    if (!step || !cart) return;

    const eddlAction = {
        trigger: 'ecommerce.checkout.step',
        event: 'ecommerceEvent',
        marketPlaceParticipant: 'Philips',
        ecommerce: {
            currencyCode: 'EUR',
            checkout: {
                actionField: {
                    step: step
                },
                products: cart.map(pr => format_product(pr, { quantity: pr.quantity }))
            }
        }
    };
    // need to remember the cart of after the payment
    try {
        localStorage.setItem('proshop_cart', JSON.stringify(cart));
    } catch (save_cart_error) {
        console.warn(save_cart_error);
    }

    return trigger_event(eddlAction);
}

const checkout_step_option_event = (step, option) => {
    if (!step || !option) return;

    const eddlAction = {
        trigger: 'ecommerce.checkout.step.option',
        event: 'ecommerceEvent',
        marketPlaceParticipant: 'Philips',
        ecommerce: {
            checkout_option: {
                actionField: {
                    step: step,
                    option: option
                },
            }
        }
    };
    return trigger_event(eddlAction);
}

const confirm_order_event = (order_data) => {

    if (!order_data) return;

    // check if we have allready pushed this order ...
    try {
        const last_order_id = localStorage.getItem('proshop_last_order_id');
        // eslint-disable-next-line eqeqeq
        if (!!last_order_id && last_order_id == order_data.id) return;
    } catch (read_order_id_error) {
        console.warn(read_order_id_error);
    }
    try {
        const cart = JSON.parse(localStorage.getItem('proshop_cart'));
        if (cart) {
            order_data.lines = cart;
        } else {
            order_data.lines = []; // if we dont have the CTN codes we better just log the $$
        }
    } catch (get_cart_error) {
        console.warn(get_cart_error);
    }
    // prevent double counting of puchases
    try {
        localStorage.setItem('proshop_last_order_id', order_data.id);
    } catch (store_order_id_error) {
        console.warn(store_order_id_error);
    }
    const eddlAction = {
        trigger: 'ecommerce.checkout.purchase',
        event: 'ecommerceEvent',
        marketPlaceParticipant: 'Philips',
        ecommerce: {
            currencyCode: 'EUR',
            purchase: {
                actionField: {
                    id: `${order_data.id}`,
                    revenue: order_data.total,
                    tax: order_data.tax,
                    shipping: order_data.shipping,
                    discount: !!order_data.discount ? order_data.discount : 0,
                    // coupon: '{{orderCoupon}}'
                },
                products: order_data.lines.map(pr => format_product(pr, { quantity: pr.quantity }))
            }
        }
    };
    return trigger_event(eddlAction);
}
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

const backwards_compatible_company = (user_info) => {
    return {...user_info, shipping_addresses: [{
        address_skey: 121314, // we now suppport just one address per company ....
        default_shipto_flag: 'Y',
        ShipToName: user_info.company_name,
        ShipToAddress1: user_info.address,
        ShipToAddress2: user_info.address_xtra || '',
        ShipToCity: user_info.city,
        ShipToState: null,
        ShipToZip: user_info.postalcode,
        ShipToCountry: user_info.country
    }]};
}

const create_temp_token = async (info) => {
    const ref = `--register--${info.email}--${window.location}`;
    // const temp_token = jwt.sign({ userId: ref }, "JWT_SECRET123!", { expiresIn: 30 });
    const secret = new TextEncoder().encode(ref);

    // const = await new CompactSign(new TextEncoder().encode(ref)).setProtectedHeader({ alg: 'ES256' }).sign( secret );
    const temp_token = await new SignJWT(info).setProtectedHeader({ alg: 'HS256' }).sign( secret ); 
    return temp_token;
}

export const JWTProvider = ({ children }) => {

    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);
    // const customization = useSelector((state) => state.customization);
    const location = useLocation();

    const local_logout = useCallback(() => {
        setSession(null);
        dispatch({ type: LOGOUT });
        dispatch({ type: SET_LOGGED_IN_REDIRECT, redirect: null });
        // console.log(customization.locale);
    }, [dispatch]);


    useEffect(() => {
        if (location && location.pathname) {
            virtual_pageview(location.pathname);
        }
    }, [location]);

    useEffect(() => {
        const init = async () => {
            try {
                const token = window.localStorage.getItem('token');
                if (token) {
                    setSession(token);
                    const response = await axios.get('/user/me?refresh=1');
                    const user_info = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            user_id: user_info.id,
                            isLoggedIn: user_info.is_valid,
                            isSalesRep: user_info.is_sales,
                            company: backwards_compatible_company(user_info)
                        }
                    });
                    dispatch({
                        type: actionTypes.SET_MAX_ORDER_AMOUNT,
                        max_order_amount: response.data.max_order_amount
                    });
                } else {
                    local_logout();
                }
            } catch (err) {
                console.error(err);
                local_logout();
            }
        };

        init();
    }, [dispatch, local_logout]);

    // const page_view = (path) => {
    //     return virtual_pageview(path);
    // };

    const product_detail = (product) => {
        return product_detail_event(product);
    }

    const addremove_product = (product, count, cart) => {
        return product_addremove_event(product, count, cart);
    }

    const show_cart = (cart) => {
        return show_cart_event(cart);
    }

    const checkout_step = (step, cart) => {
        return checkout_step_event(step, cart);
    }
    const checkout_step_option = (step, option) => {
        return checkout_step_option_event(step, option);
    }

    const confirm_order = (payment) => {
        return confirm_order_event(payment.order);
    }

    const customization = useSelector((state) => state.customization);
    // const [language, setLanguage] = useState(customization.locale);

    const login = async (name, password) => {
        const response = await axios.post(`${AUTH_API}/login`, { email: name, password });
        const token = response.data.auth;
        const user_info = response.data;
        setSession(token);

        dispatch({
            type: LOGIN,
            payload: {
                user_id: user_info.id,
                isLoggedIn: user_info.is_valid,
                isSalesRep: user_info.is_sales,
                company: backwards_compatible_company(user_info)
            }
        });

        dispatch({
            type: RESET_CART,
            max_order_amount: user_info.max_order_amount,
        })

        const updatePrefLanguage = (pref) => {
            dispatch({ type: actionTypes.THEME_LOCALE, locale: pref });
        }
        // if (user_info?.company?.language_pref === 'French' && config.spokenLanguages.length > 1){
        //     updatePrefLanguage('fr')
        // }
        // if (user_info?.company?.language_pref === 'English' && config.spokenLanguages.length > 1){
        //     updatePrefLanguage('en')
        // }
        console.log(response);
    };

    const logout = async () => {
        local_logout();

        // try {
        //     const response = await axios.get('/logout'); // not implemented yet
        //     console.log(response)
        //     if (response.status === 200) local_logout(); // sesion cleanup etc.    
        // } catch (logout_error) {
        //     console.error(logout_error);
        //     dispatch({
        //         type: SNACKBAR_OPEN,
        //         open: true,
        //         message: 'Sorry, logout failed',
        //         variant: 'alert',
        //         autoHideDuration: 3000,
        //         anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        //         alertSeverity: 'error'
        //     });
        // }
    };

    const force_logout = (message) => {
        dispatch({ type: LOGOUT });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: message || "your session expired",
            variant: 'alert',
            autoHideDuration: 3000,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            alertSeverity: 'error'
        });
        dispatch({ type: SET_LOGGED_IN_REDIRECT, redirect: null });
    };

    const login_as = async (order_for_company) => {
        try {
            const response = await axios.post('/set_order_on_behalf', { cust_no: order_for_company.cust_no });
            if (response.status === 200 && !!response.data?.company) {
                dispatch({
                    type: LOGIN_AS,
                    payload: {
                        order_for_company: response.data.company
                    }
                });
                axios.defaults.headers.common['x-order-on-behalf'] = `${order_for_company.cust_no}`;
            }
        } catch (e) {
            console.error(e);
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: e.data.message ? e.data.message : 'Could not set this office for order on behalf',
                variant: 'alert',
                autoHideDuration: 3000,
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                alertSeverity: 'error'
            });
        }
    };

    const register = async (user_info) => {
        // const { publicKey, privateKey } = await generateKeyPair('PS256')
        // console.log(publicKey)
        // console.log('------')
        // console.log(privateKey)


        // todo: this flow need to be recode as it not verified
        try {
            user_info.email = user_info.email.trim(); // remove excess spaces !            
        } catch (error) {   
            console.warn (error);
        }
        const temp_token = await create_temp_token(user_info);

        // we need to handle the api respoonse and make it look like the PC APi result

        try {
            const response = await axios.post('/user?send_mail=1', user_info, {headers: {Authorization:  `Bearer ${temp_token}`}}); // create a new user ....
            if( response.status === 200 ) {
                const account = {...response.data, success: true, messsage: "we have sent you an instruction email", message_id: "register.success_message"};
                return account;
            } else {
                // general error message
                const account = {success: false, messsage: "oops", message_id: "register.error_message"};
                return account;
            }
        } catch ( remote_error ){
            // TODO : handle errors with correct maessages
            const account = {success: false, ...remote_error, message_id: remote_error.error};
            return account;
        } 

    };

    // ask a question
    const question = async (request_info) => {
        try {
            request_info.email = request_info.email.trim(); // remove excess spaces !            
        } catch (error) {   
            console.warn (error);
        }
        // we may no be logged in, in that case we use a temp token to allow a POST on the question API
        const options = {};
        if( !axios?.defaults?.headers?.common?.Authorization ){
            const temp_token = await create_temp_token(request_info);
            options.headers =  {Authorization:  `Bearer ${temp_token}`};
        }
     
        // we need to handle the api respoonse and make it look like the PC APi result

        try {
            // const response = await axios.post('/question?send_mail=1', request_info, options);
             const response = await axios.post('/question', request_info, options);
            if( response.status === 200 ) {
                const submitted = {success: true, messsage: "your question was submitted, we will contect you ASAP", message_id: "contact.success_message"};
                return submitted;
            } else {
                // general error message
                const submitted = {success: false, messsage: "oops", message_id: "contact.error_message"};
                return submitted;
            }
        } catch ( remote_error ){
            // TODO : handle errors with correct maessages
            const submitted = {success: false, ...remote_error, message_id: remote_error.error};
            return submitted;
        } 
    };
    
    // order ZOOM Delivered
    // const zd_order = async (request_info) => {
    //     // todo: this flow need to be recode as it not verified
    //     const response = await axios.post('/zdorder', request_info);
    //     let resp = response.data;
    //     console.log(resp);

    //     return resp;
    // };
    
    const forgotPassword = async (email) => {

        const response = await axios.post(`${AUTH_API}/forgot`, {email});

        dispatch({
            type: RESET_PASSWORD,
            payload: {
                response
            }
        });
        if (response.status === 200) {
            return response.data;
        } else {
            return { success: false, message: response.data.message };
        }
    }

    const updatePassword = async (user_info) => {

        const response = await axios.put('/user/me', { password: user_info.password });

        dispatch({
            type: RESET_PASSWORD,
            payload: {
                response
            }
        });
        if (response.status === 200) {
            return response.data;
        } else {
            return { success: false, message: response.data.message };
        }
    }
    
    const resetPassword = async (payload) => { // TODO: should we support username also ?
        // the sever just wants to see the uid !
        try {
            delete payload.email;
        } catch (e) { };

        await axios.post(`${AUTH_API}/reset`, payload); // wil raise en exception if not 200 OK this is handled by the forgot component
        // const { serviceToken, user } = response.data;
        // const user = response.data;
        // TODO: should we create an FORGOT action (?)
        // dispatch({
        //     type: FORGOT,
        //     payload: {
        //         email_success: user.transaction
        //     }
        // });
        return true; // do we need this ?
    };

    const updateProfile = () => { };

    if (account.isInitialized !== undefined && !account.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{
            ...account,
            // page_view, // not need to call the from outside
            product_detail, addremove_product, show_cart, checkout_step, checkout_step_option, confirm_order,  // GA360 events
            login, login_as, logout, force_logout, register, resetPassword, question, forgotPassword, updatePassword, updateProfile
        }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
