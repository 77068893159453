// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Container, Link, Grid, Divider, Typography } from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import { contentWidth } from 'store/constant';
import LogoMark from 'ui-component/LogoMark';
import MainNavigation from '../MainNavigation';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FormattedMessage, FormattedDate } from 'react-intl';
import config from '../../../config';
import { textAlign } from '@mui/system';

import { IconCalendarStats, IconAlertTriangle } from "@tabler/icons"

import useMaintenance from 'hooks/useMaintenance';

// ==============================|| MAIN  HEADER ||============================== //

const TopBar = () => {
    const settings = useMaintenance();
    const current_lang = useSelector((state) => state.customization.locale);

    const theme = useTheme();
    const { logout, isLoggedIn, company, isSalesRep } = useAuth();
    const billonline_base = 'https://www.billonline.com/discusdental/validate.aspx';
    let billonline_hash;
    if (isLoggedIn && company && !isSalesRep && config.currencyCode !== 'CAD') {
        const token = window.localStorage.getItem('token');
        const sessionID = token.split('||').slice(-1)[0];
        const querystring = `a=${company.cust_no}&s=${sessionID}`;
        billonline_hash = btoa(querystring).replace(/[\=\+\/]/g, '');
    };
    return (
        <>
            {/* blue topbar */}
            <Box sx={{ display: 'flex', [theme.breakpoints.down('sm')]: { display:"none" }, alignItems: 'center', backgroundColor: theme.palette.primary.main, width: '100%' }}>
                <Container>
                    <Grid container sx={{ maxWidth: contentWidth, height: 48, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            <NavLink to="/" style={{ color: theme.palette.primary.light, textDecoration: 'none', cursor: 'pointer' }}><FormattedMessage id="navigation-top.top-left-text" /></NavLink>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {/* {!!billonline_hash && <Link sx={{ marginRight: '1em' }} target="_blank" href={`${billonline_base}?${billonline_hash}`} color={theme.palette.primary.light} underline='none'><FormattedMessage id="navigation-top.link-billpay" /></Link>} */}
                            <Link sx={{ marginRight: '1em' }} target="_blank" href={settings?.academy?.url ? settings.academy.url : config.links.professionals.replace('${locale}', current_lang)} color={theme.palette.primary.light} underline='none'><FormattedMessage id="navigation-top.link-professionals" /></Link>
                            {isLoggedIn && <Typography color={theme.palette.primary.light} onClick={logout} sx={{ cursor: 'pointer' }}><FormattedMessage id="navigation-top.link-log-out" /></Typography>}
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* logo and navigation bar */}
            <Box sx={{ position: 'sticky', top: 0, backgroundColor: '#FFF', zIndex: 100, marginBottom: !company?.next_order_date ? '30px' : '0px' }}>
                <Container>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '85px', width: '100%', maxWidth: contentWidth, margin: '0 auto' }}>
                        <NavLink to="/">
                            <LogoMark />
                        </NavLink>
                        <MainNavigation />
                    </Box>
                </Container>
                <Divider />
            </Box>

            {/* red bottombar */}
            {isLoggedIn &&
                <>
                    {!!company?.next_order_date || !company?.license_valid?.includes('APPROVED') &&
                        <Box sx={{ height: 72, position: 'sticky', top: 85, [theme.breakpoints.down('md')]: { paddingTop: "30px", height: "102px" }, marginTop: "-30px", zIndex: 99, display: 'flex', alignItems: 'center', backgroundColor: '#E0E0E0', width: '100%' }}>
                            <Container>
                                <Typography color={'#ff0000'} fontWeight={900} textAlign={'center'}>
                                {!!company?.next_order_date && company?.license_valid?.includes('APPROVED') &&
                                    <Box sx={{display:'flex', alignItems: 'center', justifyContent: 'center', gap: '5px'}}>
                                        <IconCalendarStats/>
                                        <FormattedMessage id="navigation-bot.new-order-date" /><FormattedDate value={company.next_order_date}/>
                                    </Box>
                                }
                                {!company?.license_valid?.includes('APPROVED') &&
                                    <Box sx={{display:'flex', flexDirection:"column" ,alignItems: 'center', justifyContent: 'center', gap: '5px'}}>
                                        <Box sx={{display:'flex', alignItems: 'center', justifyContent: 'center', gap: '5px'}}>
                                            <IconAlertTriangle/>
                                            <span><FormattedMessage id="profile.wait_for_approval" /></span>
                                        </Box>
                                        <Typography onClick={() => { logout(); }} sx={{textDecoration: "underline", fontWeight: 900, cursor: "pointer"}}><FormattedMessage id="profile.check_account_status"/></Typography>
                                    </Box>
                                }
                                </Typography>
                            </Container>
                        </Box>
                    }
                </>
            }
        </>
    );
};

export default TopBar;
