import { useSelector } from 'react-redux';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Badge, Stack, styled, Grid, ClickAwayListener } from '@mui/material';
import { AnimatePresence, motion } from "framer-motion";
import useAuth from 'hooks/useAuth';
import LocalizationSection from '../Header/LocalizationSection/index'

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import WarnIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { FormattedMessage } from 'react-intl';

import { NavLink } from 'react-router-dom';
import config from '../../../config';

// ==============================|| MAIN  HEADER ||============================== //

const StyledLink = styled(NavLink)(({ theme }) => ({
    listStyle: 'none',
    fontSize: '16px',
    marginRight: '16px',
    textDecoration: 'none',
    cursor: 'pointer',
    color: `${theme.palette.primary.dark}`,
    padding: '3px 0',
    borderBottom: '2px solid transparent',
    '&:hover': {
        borderBottom: `2px solid #2c71db`,
    }
}));
// find out how we could reuse above object. This is duplicate but else it will complain
const StyledExtLink = styled('a')(({ theme }) => ({
    listStyle: 'none',
    fontSize: '16px',
    marginRight: '16px',
    textDecoration: 'none',
    cursor: 'pointer',
    color: `${theme.palette.primary.dark}`,
    padding: '3px 0',
    borderBottom: '2px solid transparent',
    '&:hover': {
        borderBottom: `2px solid #2c71db`,
    }
}))

const MainNavigation = () => {
    const [navOpen, setNavOpen] = useState(false);
    const theme = useTheme();
    const { logout, isLoggedIn, company, isSalesRep } = useAuth();
    let current_api;
    try {
        current_api = window.we_are_connected_to;
    } catch (error) {
        current_api = '???';
    }
    const cart = useSelector((state) => state.cart);
    const current_lang = useSelector((state) => state.customization.locale);

    const itemsInCart = () => {
        let noItems = 0;
        if (cart.checkout && cart.checkout.products.length > 0) {
            for (const prod of cart.checkout.products) {
                const q = prod.quantity ? prod.quantity : 0;
                noItems = Number(noItems) + Number(q);
            }
        }
        return noItems
    }

    const billonline_base = 'https://www.billonline.com/discusdental/validate.aspx';
    let billonline_hash;
    if (isLoggedIn && company && !isSalesRep && config.currencyCode !== 'CAD') {
        const token = window.localStorage.getItem('token');
        const sessionID = token.split('||').slice(-1)[0];
        const querystring = `a=${company.cust_no}&s=${sessionID}`;
        // billonline_hash = btoa(querystring).replace(/[\=\+\/]/g, '');
    };

    const handleNav = () => {
        setNavOpen(navOpen ? false : true);
    }

    return (
        <Stack sx={{ width: "100%", justifyContent:"space-between", [theme.breakpoints.down('md')]: { justifyContent:"flex-end" } }} direction="row">
            <Box sx={{ display: 'flex', [theme.breakpoints.down('md')]: { display: 'none' } }}>
                <ul style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
                    {isLoggedIn && <StyledLink to="/"><FormattedMessage id="navigation-main.link-shop"/></StyledLink>}
                    {/* {isLoggedIn && <StyledLink to="/zoom-delivered"><FormattedMessage id="navigation-main.link-zoom-delivered"/></StyledLink>} */}
                    {isLoggedIn && isSalesRep && <StyledLink to="/order-on-behalf"><FormattedMessage id="navigation-main.link-order-on-behalf"/></StyledLink>}
                    {!isLoggedIn && <StyledLink to="/login" style={{ textDecoration: 'none', color: theme.palette.primary.dark }}><FormattedMessage id="navigation-main.link-login"/></StyledLink>
                    }
                </ul>
            </Box>

            <Stack direction="row" alignItems="center">
                <Box sx={{ display: 'flex', alignItems:"center",  [theme.breakpoints.down('md')]: { display: 'none' } }}>
                    <StyledLink to={isLoggedIn ? 'faq' : '/faq/authentication'}><FormattedMessage id="navigation-main.link-FAQ"/></StyledLink>
                    {isLoggedIn && <StyledLink to={'/contact'}><FormattedMessage id="navigation-main.link-contact-support-logged-in"/></StyledLink>}
                    {/* {!isLoggedIn &&
                        <StyledLink onClick={handleNav} style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }} target="_blank" rel="noreferrer" to={config.links.dp_sales.replace('${locale}', current_lang)} color={theme.palette.primary.light} underline='none'>
                            <FormattedMessage id="navigation-main.link-contact-support"/>
                        </StyledLink>
                    } */}
                </Box>

                <Box sx={{ display: 'block', [theme.breakpoints.down('md')]: { display: 'none' } }}>
                    <LocalizationSection nav={true} />
                </Box>

                {isLoggedIn &&
                    <>
                        <Box sx={{ display: 'flex', [theme.breakpoints.down('md')]: { display: 'none' } }}>
                            <Button component={NavLink} to="/profile/my-account" variant="round" sx={{ marginRight: '8px' }}>
                                <AccountCircleOutlinedIcon />
                            </Button>
                        </Box>
                        <Box>
                            <Badge badgeContent={itemsInCart()} color="primary" overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <Button component={NavLink} to="/cart" variant="round">
                                    <ShoppingCartOutlinedIcon />
                                </Button>
                            </Badge>
                        </Box>
                    </>
                }

                <Badge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClick={handleNav} sx={{ display: 'none', [theme.breakpoints.down('md')]: { display: 'flex' } }}>
                    <Button variant="round" sx={{ marginLeft: '8px' }}>
                        {!navOpen &&
                            <MenuIcon />
                        }
                        {!!navOpen &&
                            <CloseIcon />
                        }
                    </Button>
                </Badge>

                {current_api !== 'PRD' && <Box>
                    <Badge badgeContent={current_api} color="primary" overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <Button component={NavLink} to="/c-p/" variant="round" sx={{ marginLeft: '8px' }}>
                            <WarnIcon /> {/*  go to hidden sll products page */}
                        </Button>
                    </Badge>
                </Box>}

                {/* Responsive hamburger menu */}
                <AnimatePresence>
                {!!navOpen &&
                    <motion.div
                        initial={{ x: "100vw" }}
                        animate={{ x: 0, overflow: 'scroll', maxHeight: '90vh', position: 'absolute', top: '100%', left: 0, right: 0, backgroundColor: '#FFF', borderBottom: '1px solid #E3E3E3'}}
                        exit={{ x: "100vw" }}
                        transition={{ duration: 0.3, ease: "easeInOut"}}
                    >
                        <motion.div
                            initial={{ opacity: 1, backgroundColor: '#fff' }}
                            animate={{ opacity: 1, backgroundColor: '#fff' }}
                            exit={{ opacity: 1, backgroundColor: '#fff' }}
                        >
                            <Box sx={{ display: 'none', [theme.breakpoints.down('md')]: { display: 'block' } }}>
                                <ClickAwayListener onClickAway={handleNav}>
                                    <Grid container spacing={2} direction='column' alignItems='center' sx={{ padding: '30px 5px 30px 5px' }}>

                                        {isLoggedIn &&
                                            <Grid item>
                                                <StyledLink onClick={handleNav} to="/profile/my-account" style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }}>
                                                    <FormattedMessage id="navigation-main.link-my-account" />
                                                </StyledLink>
                                            </Grid>
                                        }

                                        <Grid item>
                                            <StyledLink onClick={handleNav} to="/" style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }}>
                                                <FormattedMessage id="navigation-top.top-left-text" />
                                            </StyledLink>
                                        </Grid>

                                        {/* {!!billonline_hash &&
                                            <Grid item>
                                                <StyledLink onClick={handleNav} style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }} target="_blank" to={`${billonline_base}?${billonline_hash}`} color={theme.palette.primary.light} underline='none'>
                                                    <FormattedMessage id="navigation-top.link-billpay" />
                                                </StyledLink>
                                            </Grid>
                                        } */}

                                        <Grid item>
                                            <StyledLink onClick={handleNav} style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }} target="_blank" to={config.links.consumers.replace('${locale}', current_lang)} color={theme.palette.primary.light} underline='none'>
                                                <FormattedMessage id="navigation-top.link-professionals" />
                                            </StyledLink>
                                        </Grid>

                                        {isLoggedIn &&
                                            <Grid item>
                                                <StyledLink onClick={handleNav} to="/" style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }} >
                                                    <FormattedMessage id="navigation-main.link-shop" />
                                                </StyledLink>
                                            </Grid>
                                        }

                                        {/* {isLoggedIn && 
                                            <Grid item>
                                                <StyledLink to="/zoom-delivered" style={{marginRight:'0px', height:'45px', display:'flex', alignItems:'center'}}>
                                                    <FormattedMessage id="navigation-main.link-zoom-delivered"/>
                                                </StyledLink>
                                            </Grid>
                                        } */}
                                       

                                        {isLoggedIn && isSalesRep &&
                                            <Grid item>
                                                <StyledLink onClick={handleNav} to="/order-on-behalf" style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }}>
                                                    <FormattedMessage id="navigation-main.link-order-on-behalf" />
                                                </StyledLink>
                                            </Grid>
                                        }

                                        <Grid item>
                                            <StyledLink onClick={handleNav} to={isLoggedIn ? 'faq' : '/faq/authentication'} style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }}>
                                                <FormattedMessage id="navigation-main.link-FAQ" />
                                            </StyledLink>
                                        </Grid>

                                        {isLoggedIn &&
                                            <Grid item>
                                                <StyledLink onClick={handleNav} to={'/contact'} style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }}>
                                                    <FormattedMessage id="navigation-main.link-contact-support-logged-in" />
                                                </StyledLink>
                                            </Grid>
                                        }

                                        {!isLoggedIn &&
                                            <Grid item>
                                                <StyledLink onClick={handleNav} style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }} target="_blank" rel="noreferrer" to={config.links.dp_sales.replace('${locale}', current_lang)} color={theme.palette.primary.light} underline='none'>
                                                    <FormattedMessage id="navigation-main.link-contact-support"/>
                                                </StyledLink>
                                            </Grid>
                                        }

                                        {isLoggedIn &&
                                            <Grid item>
                                                <StyledLink onClick={() => { logout(); handleNav(); }} style={{ marginRight: '0px', display: 'flex', alignItems: 'center' }} >
                                                    <FormattedMessage id="navigation-top.link-log-out" />
                                                </StyledLink>
                                            </Grid>
                                        }

                                        {!isLoggedIn &&
                                            <Grid item>
                                                <StyledLink onClick={handleNav} to="/login" style={{ marginRight: '0px', display: 'flex', alignItems: 'center', textDecoration: 'none', color: theme.palette.primary.dark }}>
                                                    <FormattedMessage id="navigation-main.link-login" />
                                                </StyledLink>
                                            </Grid>
                                        }

                                        <Grid item>
                                            <LocalizationSection nav={true} />
                                        </Grid>

                                    </Grid>
                                </ClickAwayListener>
                            </Box>
                        </motion.div>
                    </motion.div>
                }
                </AnimatePresence>
            </Stack>
        </Stack>
    );
};

export default MainNavigation;